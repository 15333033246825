exports.components = {
  "component---src-pages-404-index-jsx": () => import("./../../../src/pages/404/index.jsx" /* webpackChunkName: "component---src-pages-404-index-jsx" */),
  "component---src-pages-buy-index-jsx": () => import("./../../../src/pages/buy/index.jsx" /* webpackChunkName: "component---src-pages-buy-index-jsx" */),
  "component---src-pages-contact-thanks-index-jsx": () => import("./../../../src/pages/contact/thanks/index.jsx" /* webpackChunkName: "component---src-pages-contact-thanks-index-jsx" */),
  "component---src-pages-lend-index-jsx": () => import("./../../../src/pages/lend/index.jsx" /* webpackChunkName: "component---src-pages-lend-index-jsx" */),
  "component---src-pages-manage-index-jsx": () => import("./../../../src/pages/manage/index.jsx" /* webpackChunkName: "component---src-pages-manage-index-jsx" */),
  "component---src-pages-rent-index-jsx": () => import("./../../../src/pages/rent/index.jsx" /* webpackChunkName: "component---src-pages-rent-index-jsx" */),
  "component---src-pages-sale-index-jsx": () => import("./../../../src/pages/sale/index.jsx" /* webpackChunkName: "component---src-pages-sale-index-jsx" */),
  "component---src-pages-staff-index-jsx": () => import("./../../../src/pages/staff/index.jsx" /* webpackChunkName: "component---src-pages-staff-index-jsx" */),
  "component---src-templates-0-article-index-jsx": () => import("./../../../src/templates/0_article/index.jsx" /* webpackChunkName: "component---src-templates-0-article-index-jsx" */),
  "component---src-templates-1-top-index-jsx": () => import("./../../../src/templates/1_top/index.jsx" /* webpackChunkName: "component---src-templates-1-top-index-jsx" */),
  "component---src-templates-2-about-index-jsx": () => import("./../../../src/templates/2_about/index.jsx" /* webpackChunkName: "component---src-templates-2-about-index-jsx" */),
  "component---src-templates-4-blog-index-jsx": () => import("./../../../src/templates/4_blog/index.jsx" /* webpackChunkName: "component---src-templates-4-blog-index-jsx" */),
  "component---src-templates-5-privacy-index-jsx": () => import("./../../../src/templates/5_privacy/index.jsx" /* webpackChunkName: "component---src-templates-5-privacy-index-jsx" */),
  "component---src-templates-6-contact-index-jsx": () => import("./../../../src/templates/6_contact/index.jsx" /* webpackChunkName: "component---src-templates-6-contact-index-jsx" */)
}

